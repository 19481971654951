import { Injectable } from '@angular/core';
import {SwUpdate, UnrecoverableStateEvent, VersionEvent} from "@angular/service-worker";

@Injectable({
  providedIn: 'root'
})
export class UpdatePwaService {

  constructor(private updates: SwUpdate) {
    if (this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe((event: VersionEvent) => {
        if (event.type === 'VERSION_READY') {
          this.updates.activateUpdate().then(() => document.location.reload());
        }
      });

      this.updates.unrecoverable.subscribe((event: UnrecoverableStateEvent) => {
        console.error('An unrecoverable error occurred:', event.reason);
        alert('An error occurred that requires a page reload. Please refresh the page.');
      });
    }

  }

  checkForUpdates() {
    this.updates.checkForUpdate().then(() => {
      console.log('Checked for updates.');
    }).catch(err => {
      console.error('Error when checking for update:', err);
    });
  }

  promptUser() {
    if (confirm('New version available. Load new version?')) {
      this.updates.activateUpdate().then(() => document.location.reload());
    }
  }
}
