import {Component, OnInit} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {SideBarComponent} from "./components/navigation/side-bar/side-bar.component";
import {HeaderComponent} from "./components/navigation/header/header.component";
import { LoginComponent } from './auth/login/login.component';
import {UpdatePwaService} from "./core/services/update-pwa.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SideBarComponent, HeaderComponent,LoginComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{

  constructor(private pwaService: UpdatePwaService) {
    this.pwaService.checkForUpdates()
  }
  ngOnInit() {
  }


  title = 'Concierge';
}
