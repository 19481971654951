import { HttpInterceptorFn } from '@angular/common/http';
import {LocalStorageUtil} from "../utils/localStorageUtil";
import {Tokens} from "../models/Auth";
import {ProjectService} from "../services/project.service";
import {inject} from "@angular/core";
import {catchError, finalize} from "rxjs";
import {AuthService} from "../services/auth.service";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService = inject(ProjectService);
  const authService = inject(AuthService);
  const token = LocalStorageUtil.getItem<Tokens>('userToken')?.access.token;

  const clonedRequest = token
    ? req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    })
    : req;

  loadingService.show();

  return next(clonedRequest).pipe(
    finalize(() => loadingService.hide()),
    catchError(err => {
      if (err.status === 401) {
        authService.logout(); // Call logout function from AuthService on 401 Unauthorized
      }
      throw err;
    })
  );
};
