import {Routes} from '@angular/router';
import {authGuard} from "./core/guards/auth.guard";

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./auth/login/login.component').then(mod => mod.LoginComponent)
  },
  {
    canActivate: [authGuard],
    path: 'project',
    loadChildren: () => import('./modules/project/project.module').then(m => m.ProjectModule)
  },
  {
    path: '',
    loadComponent: () => import('./auth/login/login.component').then(mod => mod.LoginComponent)
  },
];
